.ChartButtons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  > div {
    width: 33.333%;
  }

  span {
    color: #4d515f;
    display: block;
  }

  .ChartButton {
    background: #dddddd;
    border: none;
    border-radius: 2px;
    color: #000000;
    font-size: 21px;
    line-height: 17px;
    letter-spacing: 0.04em;
    padding: 8px 10px;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }

    &.ChartButtonActive {
      background: #84b0da;
      color: #fff;
    }
  }

  .ChartButtonCenter {
    padding: 0 34px;

    span {
      text-align: center;
    }

    .ChartTablePeriod {
      background: #f9f9f9;
      border-radius: 4px;
      padding: 13px 36px;
      text-align: center;

      span {
        color: #4d515f;
        display: block;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.02em;
        margin-bottom: 6px;
      }

      div {
        color: #000000;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
      }
    }
  }
}

.ChartBox {
  display: none;

  &.ChartBoxActive {
    display: block;
  }
}

.DetailCards {
  border-bottom: 1px solid #b1bada;
  padding: 32px 35px;

  .DetailCard {
    border: 1px solid #b1bada;
    border-radius: 4px;
    padding: 26px 40px 27px;

    .CardTitle {
      color: #000000;
      font-weight: bold;
      font-size: 40px;
      line-height: 48px;
    }

    .CardSubtitle {
      color: #4d515f;
      font-size: 18px;
      line-height: 22px;
    }
  }
}

.exportExcel {
  background: #1513c3;
  border-radius: 100px;
  border: none;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-transform: uppercase;
  padding: 8px 55px;
}

.menuItem {
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: smaller;
}

.UsageLoading {
  background: #ffffff;
  margin-bottom: 20px;
}

