.Tabs {
  overflow: hidden;
  width: 100%;

  .TabHeaderMobile {
    background-color: #fff;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    display: flex;

    ul {
      border-bottom: 1px solid #dddddd;

      justify-content: center;
      padding: 0;
      margin: 0;
      width: 100%;

      li {
        list-style: none;
        width: inherit;
        text-align: center;

        .MobileTab {
          align-items: center;
          border-right: 1px solid #dddddd;
          color: #4d515f;
          cursor: pointer;
          display: flex;
          font-size: 18px;
          line-height: 22px;
          justify-content: center;
          text-decoration: none;
          padding: 23px 0;
          position: relative;

          object {
            margin-right: 23px;

            svg {
              path {
                fill: #000;
              }
            }
          }

          &.TabActive {
            background: #1513c3;
            border: none;
            color: #fff;

            &:after {
              background: #84b0da;
              bottom: -1px;
              content: '';
              height: 4px;
              left: 0;
              position: absolute;
              width: 100%;
            }

            object {
              svg {
                path {
                  fill: #fff;
                }
              }
            }
          }
        }

        &:last-child a {
          border: none;
        }
      }
    }
  }

  .MuiIcon {
    color: #fff;
  }

  .selectMui {
    padding: 20px;
    background-color: #1513c3;
    color: #fff;
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: smaller;
    box-shadow: 30px solid #84b0da;
    &:focus {
      background-color: #1513c3;
    }
  }

  .TabHeader {
    background-color: #fff;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    display: flex;

    ul {
      border-bottom: 1px solid #dddddd;
      display: flex;
      justify-content: space-around;
      padding: 0;
      margin: 0;
      width: 100%;

      li {
        list-style: none;
        width: inherit;
        text-align: center;

        a {
          align-items: center;
          border-right: 1px solid #dddddd;
          color: #4d515f;
          cursor: pointer;
          display: flex;
          font-size: 18px;
          line-height: 22px;
          justify-content: center;
          text-decoration: none;
          padding: 23px 0;
          position: relative;

          object {
            margin-right: 23px;

            svg {
              path {
                fill: #000;
              }
            }
          }

          &.TabActive {
            background: #1513c3;
            border: none;
            color: #fff;

            &:after {
              background: #84b0da;
              bottom: -1px;
              content: '';
              height: 4px;
              left: 0;
              position: absolute;
              width: 100%;
            }

            object {
              svg {
                path {
                  fill: #fff;
                }
              }
            }
          }
        }

        &:last-child a {
          border: none;
        }
      }
    }
  }

  .TabBody {
    .TabBodyInner {
      background: #fff;
      border-radius: 4px;
      padding: 24px 20px;
      margin-bottom: 10px;
    }

    .TabContent {
      display: none;

      &.TabContentActive {
        display: block;
      }
    }
  }
}
