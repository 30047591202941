.PowerUsage {
  background: #ffffff;
  margin-bottom: 20px;

  .apexcharts-toolbar {
    display: none;
  }

  .PowerUsageInner {
    padding: 20px 20px 0;

    .PowerUsageHeader {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      h3 {
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        margin: 0;
      }
      h5 {
        font-weight: normal;
        font-size: 15px;
        line-height: 22px;
        margin: 0;
        font-weight: 500;
      }

      .PowerUsageHeaderRight {
        align-items: center;
        display: flex;
        .PowerUsageHeaderTabsMobile {
          button {
            background: #dddddd;
            border: none;
            border-radius: 2px;
            cursor: pointer;
            padding: 0px 3px;
            margin: 0 3px;

            &.PowerUsageHeaderTabActive,
            &:hover {
              background: #36c2cf;
              color: #fff;
            }
          }
        }
        .PowerUsageHeaderTabs {
          button {
            background: #dddddd;
            border: none;
            border-radius: 2px;
            cursor: pointer;
            padding: 5px 10px;
            margin: 0 5px;

            &.PowerUsageHeaderTabActive,
            &:hover {
              background: #36c2cf;
              color: #fff;
            }
          }
        }

        .PowerUsageHeaderTabDate {
          font-size: 12px;
          line-height: 14px;
          text-align: right;
          // margin-left: 25px;
        }
      }
    }

    .PowerUsageHeaderTableBody::-webkit-scrollbar {
      display: none;
    }

    .PowerUsageHeaderTableBody {
      overflow-x: auto;
      scrollbar-width: none;
      > div {
        display: none;

        &.PowerUsageHeaderTableBodyActive {
          display: block;
          min-width: 500px;

          .noData {
            width: 100%;
            height: 221px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
          }
        }
      }
    }
  }
}
